import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import ContentfulAssets from '../../hooks/useContentfulAssets';
import * as Styled from './contentlistWithImageStyles';
import BlueLine from '../blueLine/blueLine'

const RichTextImageParagraph = ({ sectionData }) => {
    const title =  sectionData?.title || 'Commercial Solar Offering';
    
    const optionsMainStyle = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const asset = ContentfulAssets(node?.data?.target?.sys?.id);
            if (asset) {
            return (
                <Styled.Image
                className={asset?.node?.description}
                src={asset?.node?.file?.url}
                alt={asset?.node?.title}
                />
            );
            }
        },
        [BLOCKS.HEADING_2]: (node, children) => (
            <Styled.Header>{children}</Styled.Header>
        )
        },
        renderText: (text) =>
        text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    };

    const optionsPDFStyle = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const asset = ContentfulAssets(node?.data?.target?.sys?.id);
                if (asset) {
                return (
                    <Styled.CallToActionButton
                    href={asset?.node?.file?.url}
                    target='_self'
                    download
                    >Learn More
                    </Styled.CallToActionButton>
                );
                }
            },
            },
        };
return sectionData?.list?.length>0 && (
    <Styled.MainDiv>
        <BlueLine />
        <Styled.TitleContainer>
                <Styled.LineBehind><Styled.LineBehindSpan>{title}</Styled.LineBehindSpan></Styled.LineBehind>
            </Styled.TitleContainer>
            <Styled.BlueLine></Styled.BlueLine>
        <Styled.MainContainer>
        {sectionData?.list?.map((item,index)=>{
            return(
                <Styled.Column key={index}>{documentToReactComponents(
                    JSON.parse(item?.contentDetails?.raw),
                    optionsMainStyle
                    )}</Styled.Column>
            );
        })}
        </Styled.MainContainer>
        <Styled.CallToActionContainer>
        {documentToReactComponents(
        JSON.parse(sectionData?.listDetails?.raw),
        optionsPDFStyle
        )}
    </Styled.CallToActionContainer>
    </Styled.MainDiv>
);
};

export default RichTextImageParagraph;

