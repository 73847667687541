import styled from "styled-components";

export const MainDiv = styled.div`
  justify-content: center;

  //background-color: #EDEDED8C;
  padding-top: 180px;
  padding-bottom: 10px;
  @media (max-width: 1024px) {
    padding-top: 170px;
  }
  @media (max-width: 768px) {
    padding-top: 130px;
    padding-bottom: 20px;
    //background: linear-gradient(#F9F9F9, #E0E0E0);
  }
  @media (max-width: 425px) {
    padding-top: 150px;
  }
`;

export const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Column = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 30px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
`;

export const Header = styled.p`
  font-size: 20px;
  font-weight: bold;
  padding-top: 15px;
  width: 160px;
  color:var(--font-color);
  @media (max-width: 1024px) {
    font-size: 20px;
    padding-top: 20px;
    width: 160px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    color: #43474d;
    width: 130px;
    padding-top:5px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    width: 115px;
  }
`;

export const Image = styled.img`
  display: inline;
  padding-right: 30px;
  padding-left: 30px;
  width: 165px;
  height: 112px;
  margin-bottom: 20px;

  &.large{
    width:175px;
    @media (max-width: 425px) {
      width: 160px;
    }
  }

  &.small{
    width:155px;
    @media (max-width: 425px) {
      width: 150px;
    }
  }

  @media (max-width: 425px) {
    width: 150px;
    margin-bottom: 5px;
  }
`;

export const Title = styled.h1`
  background-color: #fff;
  padding: 20px;
  font-size: 42px;
  font-weight: bold;
  background-color: #f5f5f5;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const LineBehind = styled.h2`
  width: 70%;
  text-align: center;

  border-bottom: 2px solid #007dc3;
  line-height: 0.1em;
  margin: 10px 0 20px;
  //background-color: #F5F5F5;
  @media (max-width: 1440px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    border-bottom: none;
    width: auto;
  }
`;

export const LineBehindSpan = styled.span`
  background-color: #fff;
  padding-left: 50px;
  padding-right: 50px;
  font-weight: bold;
  font-size: 40px;
  color:var(--font-color);
  ${LineBehind} {
    background-color: #f5f5f5;
    padding: 0 10px;
  }

  @media (max-width: 1440px) {
    font-size: 36px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    padding: 0;
  }
  @media (max-width: 425px) {
    font-size: 20px;
  }
  @media (max-width: 375px) {
    font-size: 20px;
  }
  @media (max-width: 360px) {
    font-size: 18px;
  }
  @media (max-width: 360px) {
    font-size: 16px;
    line-height:26px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const CallToActionContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const CallToActionButton = styled.a`
  background-color: #00ae4f;
  padding: 15px 75px 15px 75px;
  color: white;
  font-size: 25px;
  border-radius: 10px;
  font-weight: bold;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: white;
  }
  @media (max-width: 768px) {
    padding: 15px 100px 20px 100px;
    font-size: 16px;
    border-radius: 10px;
  }
  @media (max-width: 425px) {
    padding:10px 60px 10px 60px;
  }
`;

export const BlueLine = styled.hr`
  display: none;
  background-color: #007dc3;
  height: 2px;
  border-top: 1px solid #007dc3 !important;

  @media (max-width: 768px) {
    display: inline;
  }
`;
